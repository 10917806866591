*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%; /* 16px x 62.5 = 10px = 1rem */
}

body {
  height: 100vh;
  font-size: 1.6rem;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  height: 100%;
}

.container-fluid{
  width: 100%;
}

.container{
  width: 100%;
}

.container-xs{
  width: 100%;
}

.container-md{
  width: 100%;
}

.container-lg{
  width: 100%;
}

/* Margin and Padding */

.p-1{
  padding: 1px;
}

.p-2{
  padding: 2px;
}

.p-3{
  padding: 3px;
}

.p-5{
  padding: 5px;
}

.p-10{
  padding: 10px;
}

.m-1{
  margin: 1px;
}

.m-2{
  margin: 2px;
}

.m-3{
  margin: 3px;
}

.m-5{
  margin: 5px;
}

.m-10{
  margin: 10px;
}

.pt-1{
  padding-top: 1px;
}

.pt-2{
  padding-top: 2px;
}

.pt-3{
  padding-top: 3px;
}

.pt-5{
  padding-top: 5px;
}

.pt-10{
  padding-top: 10px;
}

.mt-1{
  margin-top: 1px;
}

.mt-2{
  margin-top: 2px;
}

.mt-3{
  margin-top: 3px;
}

.mt-5{
  margin-top: 5px;
}

.mt-10{
  margin-top: 10px;
}
.pb-1{
  padding-bottom: 1px;
}

.pb-2{
  padding-bottom: 2px;
}

.pb-3{
  padding-bottom: 3px;
}

.pb-5{
  padding-bottom: 5px;
}

.pb-10{
  padding-bottom: 10px;
}

.mb-1{
  margin-bottom: 1px;
}

.mb-2{
  margin-bottom: 2px;
}

.mb-3{
  margin-bottom: 3px;
}

.mb-5{
  margin-bottom: 5px;
}

.mb-10{
  margin-bottom: 10px;
}
.pl-1{
  padding-left: 1px;
}

.pl-2{
  padding-left: 2px;
}

.pl-3{
  padding-left: 3px;
}

.pl-5{
  padding-left: 5px;
}

.pl-10{
  padding-left: 10px;
}

.ml-1{
  margin-left: 1px;
}

.ml-2{
  margin-left: 2px;
}

.ml-3{
  margin-left: 3px;
}

.ml-5{
  margin-left: 5px;
}

.ml-10{
  margin-left: 10px;
}

.pr-1{
  padding-right: 1px;
}

.pr-2{
  padding-right: 2px;
}

.pr-3{
  padding-right: 3px;
}

.pr-5{
  padding-right: 5px;
}

.pr-10{
  padding-right: 10px;
}
.mr-1{
  margin-right: 1px;
}

.mr-2{
  margin-right: 2px;
}

.mr-3{
  margin-right: 3px;
}

.mr-5{
  margin-right: 5px;
}

.mr-10{
  margin-right: 10px;
}

.d-inline {
  display: inline;
}

.d-flex{
  display:flex;
  justify-content: space-between;
}

/* BreakPoints 576, 768, 992, */



/* For Mobile */
@media only screen and (max-width: 576px) {
  .col-xs-1 {width: 8.33%;}
  .col-xs-2 {width: 16.66%;}
  .col-xs-3 {width: 25%;}
  .col-xs-4 {width: 33.33%;}
  .col-xs-5 {width: 41.66%;}
  .col-xs-6 {width: 50%;}
  .col-xs-7 {width: 58.33%;}
  .col-xs-8 {width: 66.66%;}
  .col-xs-9 {width: 75%;}
  .col-xs-10 {width: 83.33%;}
  .col-xs-11 {width: 91.66%;}
  .col-xs-12 {width: 100%;}

  /*.form-setup{
    display: block;
    justify-content: space-evenly;
  }*/
  
}

/* For Tablet */
@media only screen and (max-width: 768px) and (min-width: 576px) {
  .col-sm-1 {width: 8.33%;}
  .col-sm-2 {width: 16.66%;}
  .col-sm-3 {width: 25%;}
  .col-sm-4 {width: 33.33%;}
  .col-sm-5 {width: 41.66%;}
  .col-sm-6 {width: 50%;}
  .col-sm-7 {width: 58.33%;}
  .col-sm-8 {width: 66.66%;}
  .col-sm-9 {width: 75%;}
  .col-sm-10 {width: 83.33%;}
  .col-sm-11 {width: 91.66%;}
  .col-sm-12 {width: 100%;} 

  /*.form-setup{
    display: flex;
    justify-content: space-evenly;
  }
  */
}

/* For Medium Display */
@media only screen and (max-width: 992px) and (min-width: 769px) {

  /* 12 col grid */
  .col-md-1 {width: 8.33%;}
  .col-md-2 {width: 16.66%;}
  .col-md-3 {width: 25%;}
  .col-md-4 {width: 33.33%;}
  .col-md-5 {width: 41.66%;}
  .col-md-6 {width: 50%;}
  .col-md-7 {width: 58.33%;}
  .col-md-8 {width: 66.66%;}
  .col-md-9 {width: 75%;}
  .col-md-10 {width: 83.33%;}
  .col-md-11 {width: 91.66%;}
  .col-md-12 {width: 100%;}

  /*.form-setup{
    display: flex;
    justify-content: space-evenly;
  }*/
  
}

/* For Large Display */

@media only screen and (min-width: 993px) {

  /* 12 col grid */
  .col-lg-1 {width: 8.33%;}
  .col-lg-2 {width: 16.66%;}
  .col-lg-3 {width: 25%;}
  .col-lg-4 {width: 33.33%;}
  .col-lg-5 {width: 41.66%;}
  .col-lg-6 {width: 50%;}
  .col-lg-7 {width: 58.33%;}
  .col-lg-8 {width: 66.66%;}
  .col-lg-9 {width: 75%;}
  .col-lg-10 {width: 83.33%;}
  .col-lg-11 {width: 91.66%;}
  .col-lg-12 {width: 100%;}

  /*.form-setup{
    display: flex;
    justify-content: space-evenly;
  }*/
}


/* Layout */
.grid-container {
  display: grid;
  grid-template-areas:
    'header' 
    'main'
    'footer';
    grid-template-columns: 1fr ;
    grid-template-rows: 5rem 1fr 5rem;
    height: 100%;
}

@media only screen and (max-width: 768px){
  .grid-container {
    display: grid;
    grid-template-areas:
      'header' 
      'main'
      'footer';
    grid-template-columns: 1fr ;
    grid-template-rows: 10rem 1fr 5rem;
    height: 100%;
  }
}
header {
  grid-area: header;
  background-color: blue;
  position: fixed;
  width: 100%;
  z-index: 1000;
}

main {
  grid-area: main;
  padding: 1rem;
  margin: 1rem;
}
footer {
  grid-area: footer;
  background-color: blue;
  color: #fff;
}

/* Common */
/*h1 {
  font-size: 3.2rem;
  padding: 1rem 0;
}
h2 {
  font-size: 2.4rem;
  padding: 1rem 0;
}
h3 {
  font-size: 1.9rem;
  padding: 1rem 0;
}
h4 {
  font-size: 1.6rem;
  padding: 1rem 0;
}
h5 {
  font-size: 1.33rem;
  padding: 1rem 0;
}
h6 {
  font-size: 1.07rem;
  padding: 1rem 0;
}*/

a {
  text-decoration: none;
  border: .1rem blue solid;
  margin: 1rem;
  padding: 1rem;
  border-radius: 25rem;
  color: blue;
}

a:hover {
  color: orange;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

li {
  margin-top: 1rem;
}

input{
  padding: 1rem;
  border-radius: 0.5rem;
  border: 0.1rem silver solid;
  font-size: 1.6rem;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

select,
textarea,
button {
  padding: 1rem;
  border-radius: 0.5rem;
  border: 0.1rem #c0c0c0 solid;
  font-size: 1.6rem;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

button {
  cursor: pointer;
}

.button-link {
  padding: 1rem;
  border-radius: 0.5rem;
  border: 0.1rem #c0c0c0 solid;
  font-size: 1.6rem;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer;
  background-color: #4080f0;
  color: #000000;
  transition: .4s;
}

input:hover,
select:hover,
textarea:hover
button:hover {
  border: 0.1rem silver solid;
  opacity: .6;
}

input:disabled,
select:disabled,
textarea:disabled,
button:disabled {
  border: 0.1rem silver solid;
  background-color: silver;
}

.primary {
  background-color: blue;
  color: #FFF;
  transition: .4s;
}

.desplay-block{
  display: block;
}

.budget{
  border-color: lime;
}

button.ATC {
  background-color: orange;
}

button.block {
  width: 100%;
}

button.small {
  font-size: 1.2rem;
}

/* Header */

header a {
  color: #ffffff;
  padding: 1em;
}
a.brand {
  color: #ffffff;
  font-size: 2.4rem;
  font-weight: bold;
}
.badge {
  background-color: #f02020;
  color: #ffffff;
  border-radius: 50%;
  padding: 0.2rem 0.7rem;
  font-size: 1.4rem;
  margin-left: 0.2rem;
}
.row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.row.center {
  justify-content: center;
}
.row.top {
  align-items: flex-start;
}
.row.start {
  justify-content: flex-start;
}
.col-1 {
  flex: 1 1 10rem;
}
.col-2 {
  flex: 1 1 10rem;
}
.col-3 {
  flex: 32 1 75rem;
}
.min-30 {
  min-width: 30rem;
}
.p-1 {
  padding: 1rem;
}
/* Aside */
aside {
  position: fixed;
  width: 30rem;
  height: 100%;
  background-color: #efefef;
  z-index: 10000;
  transform: translateX(-30rem);
  transition: all 0.5s;
}
aside.open {
  transform: translateX(0);
}
button.open-sidebar {
  font-size: 2.2rem;
  padding: 0.2rem 0.2rem;
  margin: 0 0.5rem;
  background: none;
  color: #ffffff;
  cursor: pointer;
}
button.open-sidebar:hover {
  border-color: #ffffff;
}
aside ul {
  padding: 0;
  list-style: none;
}
aside li {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}
button.close-sidebar {
  padding: 0.3rem 0.8rem;
}

/* Image */
img {
  border-radius: 0.5rem;
}
img.small {
  max-width: 5rem;
  width: 100%;
}
img.medium {
  height: auto;
  width: 18vw;
}
img.large {
  max-height: 30rem;
  width: auto;
}

/* Card */
.frame {
  border: none;
  margin: 1rem;
  display: flex;
}

.description p{
  display: block;
  margin: .1rem;
}

.cards {
  border: none;
  margin: 0;
  flex: 2;
}

.map{
  border: 0.1rem #c0c0c0 solid;
  background-color: #f8f8f8;
  border-radius: 0.5rem;
  margin: 1rem;
  flex: 1;
}

.card {
  display: flex;
  border: 0.1rem #c0c0c0 solid;
  background-color: #f8f8f8;
  border-radius: 0.5rem;
  margin: 1rem;
  padding: auto;
}

.card .img{
  flex: 1;
}

.card-img {
  width: 100%;
}
.card .description{
  flex: 1;
}

.card-body {
  padding: 1rem;
}
.card-body > * {
  margin-bottom: 0.5rem;
}
.price {
  font-size: 2rem;
}
/* Rating */
.rating {
  font-size:smaller;
}
.rating span {
  color: #f0c040;
  margin: 0.1rem;
}
.rating span:last-child {
  color: #404040;
}
/* Alert */
.loading {
  display: block !important;
  margin: 1.8rem;
  font-size: 3.6rem;
  color: blue;
}
.success {
  color: #20a020;
}
.danger {
  color: #a02020;
}
.alert {
  padding: 1rem;
  border: 0.1rem solid transparent;
  border-radius: 0.5rem;
}
.alert-info {
  color: #2020a0;
  background-color: #e0e0ff;
}
.alert-danger {
  color: #a02020;
  background-color: #ffe0e0;
}
.alert-success {
  color: #20a020;
  background-color: #eeffe0;
}
/* Form */
.form {
  position: relative;
  max-width: 100%;
  display: block;
  align-items: center;
  text-align: center;
  justify-content: center;
  background: #eee;
  margin: 1rem auto;
  font-size: 16px;
  border: 1px solid blue;
  border-radius: 5px;
  padding: 20px;
}

.form-item {
  position: relative;
  display: block;
  align-items: center;
  text-align: center;
  justify-content: center;
  background: lemonchiffon;
  margin: 0 auto;
  font-size: 16px;
  border: 1px solid silver;
  border-radius: 5px;
  padding: 20px;
}

.formRegister{
  max-width: 100%;
  position: relative;
  display: block;
  align-items: center;
  text-align: center;
  justify-content: center;
  background: lemonchiffon;
  margin: 0 auto;
  font-size: 16px;
  border: 1px solid silver;
  border-radius: 5px;
  padding: 20px;
}



.mobileInput{
  display: flex;
}
.isdCode{
  border: none;
  border-radius: 0;
  width: 50px;

}

.view{
  margin: 15px;
}

.fat{
  font-weight: bold;
}

.reset{
  background-color: yellowgreen;
  color: #fff;
}
.mobile{
  border: none;
  border-radius: 0;
  width: 160px;
}
.input-field{
  display: flex;
  margin: 25px 0px 25px 8px;
  /*height: 50px;
  width: 100%;*/
  align-items: center;
}

.input-field input,
.input-field select{
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  font-size: 16px;
}


/* for Log in panel*/
.input-login{
  margin: 25px 0;
  position: relative;
  height: 50px;
  width: 100%;
}

.input-check{
  margin: 15px 0;
  position: relative;
  height: 30px;
  width: 100%;
}

.input-login input{
  height: 100%;
  width: 100%;
  border: 1px solid silver;
  padding-left: 15px;
  outline: none;
  font-size: 16px;
  transition: .4s;
}

.input-login label, span.show{
  position: absolute;
  top: 40%;
  transform: translateY(-25%);
}

.input-login label{
  left: 15px;
  pointer-events: none;
  color: grey;
  font-size: 18px;
  transition: .4s;
}



/* Styles for input and select elements */


.input-field label {
  flex: 1;
  margin: 8px;
  font-weight: bold;
  text-align: left;
}

.input-field a {
  flex: 2;
}

.total{
  border-style:  solid none none none;
  border-color: #1DA1F2;
}

.select-field{
  margin: 25px 0;
  position: relative;
  height: 50px;
  width: 100%;
}

select:focus{
  border: 1px solid #1DA1F2;
}

.select-field select{
  height: 100%;
  width: 100%;
  border: 1px solid #1DA1F2;
  background-color: white;
  padding-left: 15px;
  outline: none;
  font-size: 16px;
}

.textarea-field{
  width: 100%;
  height: 100%;
}

.textarea-field textarea{
  width: 100%;
  height: 100%;
  border: 1px solid #1DA1F2;
  background-color: white;
  padding-left: 15px;
  outline: none;
  font-size: 16px;
}

.textarea-field label{
  top: 10px;
  left: 15px;
  pointer-events: none;
  color: grey;
  font-size: 16px;
  transition: .4s;
}



textarea:focus{
  border: 1px solid #1DA1F2;
}




.input:focus{
  border: 1px solid #1DA1F2;
}




input:focus ~ label,
input:valid ~ label,
input:disabled ~ label{
  transform: translateY(-28px);
  background: white;
  font-size: 16px;
  color: #00b4ff;
}
fieldset{
  border-color: #1DA1F2;
  border-radius: 5px;
}
fieldset > legend{
  color: #00b4ff;
}


.button button{
  width: 260px;
  height: 100%;
  color:white;
  font-size: 20px;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
}

.btn-min-width{
  min-width: 30%;
}

.button{
  border-radius: 5px;
  margin: 2rem;
  padding: auto;
  position: relative;
  flex: 1;
  width: 26rem;
  background-color: blue;
  color: whitesmoke;
  display: block;
  align-content: center;
  text-align: center;
  align-self: center;
}

.dis-button{
  display: block;
}



.button-container{
  width: auto;
  display: inline-block;
  margin: 1rem auto 1rem auto;
  border: #2020a0 solid;
  border-radius: 1.5rem;

}



.redirect{
  margin: 15px 0 15px 0;
}

/* Dropdown */
.dropdown {
  display: inline-block;
  position: relative;
}
.dropdown-content {
  position: absolute;
  display: none;
  right: 0;
  min-width: 15rem;
  padding: 1rem;
  z-index: 1;
  background-color: blue;
  margin: 0;
  margin-top: 0.4rem;
  border-radius: 0.5rem;
}
.dropdown:hover .dropdown-content {
  display: block;
}
/* Checkout Steps */
.checkout-steps > div {
  border-top: 0.3rem #c0c0c0 solid;
  color: #c0c0c0;
  flex: 1;
  padding: 1rem;
  font-weight: bold;
}
.checkout-steps > div.active {
  border-top-color: orange;
  color: orange;
}
/* Table */
.table {
  width: 100%;
  border-collapse: collapse;
  overflow-x: scroll;
}
.table tbody tr:nth-of-type(odd) {
  background-color: #eee;
}
.table td,
.table th {
  text-align: center;
  border: 0.1rem solid #e4e4e4;
  padding: 0.5rem;
  white-space: nowrap;
  max-width: 25rem;
  overflow: hidden;
}

.table-container {
  overflow-x: auto;
}





.table button {
  margin: 0 0.2rem;
}
/* Carousel */
.carousel .slide img {
  max-width: 30rem;
}
/* Search bar*/

.search-bar{
  max-width: 50%;
  margin: auto;
  display: flex;
  border: 0;
  padding: .5em;
  justify-content: center;
}

/*@media only screen and (max-width: 768px) {
  .search-bar{
    width: 100%;
  }
}*/

.search-bar select, .search-bar input{
  flex: 1;
  border-radius: 0 0 0 0;
  padding: .075em;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.search-bar:first-child{
  border-radius: 0 .375em .375em 0;
}

.search-bar button{
  border-radius: 45%;
}

/*.search-bar input{
  background-color: #fff;
  border-radius: 0.375em 0 0 0.375em;
  box-shadow: 0 0 0 0.1em #d9d9d9 inset ;
  padding: 0.75em;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 80%;
}*/


.search-btn{
  background-color: orange;
  border-radius: 0 0.375em  0.375em 0;
  padding: 0.75em;
}

/* Map */
.full-container {
  margin: -1rem;
  height: calc(100% + 2rem);
}
.map-input-box {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0.5rem auto;
  width: 25rem;
  height: 4rem;
  display: flex;
}
.map-input-box input {
  border-radius: 1rem 0 0 1rem;
  border-right: 0;
}
.map-input-box button {
  border-radius: 0 1rem 1rem 0;
  border-left: 0;
}
/* Pagination */
.pagination a {
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
  border: 0.1rem #a4a4a4 solid;
  font-size: 1.6rem;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.pagination a.active {
  font-weight: bold;
}

/* Dashboard */

.summary {
  border: 0.1rem #c0c0c0 solid;
  margin: 2rem;
  border-radius: 0.5rem;
  /*flex: 1 1 20rem;*/
}
.summary-title {
  font-size: 2rem;
  padding: 1rem;
}
.summary-body {
  font-size: 4rem;
  padding: 1rem;
  text-align: center;
}

.summary-title.color1 {
  background-color: #f0e0e0;
}
.summary-title.color2 {
  background-color: #e0f0e0;
}
.summary-title.color3 {
  background-color: #e0e0f0;
}

/* Chatbox */
.chatbox {
  color: #000000;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
}
.chatbox ul {
  overflow: scroll;
  max-height: 20rem;
}
.chatbox li {
  margin-bottom: 1rem;
}
.chatbox input {
  width: calc(100% - 9rem);
}

.support-users {
  background: #f0f0f0;
  height: 100%;
}
.support-users li {
  background-color: #f8f8f8;
}
.support-users button {
  background-color: transparent;
  border: none;
  text-align: left;
}
.support-users li {
  margin: 0;
  background-color: #f0f0f0;
  border-bottom: 0.1rem #c0c0c0 solid;
}

.support-users li:hover {
  background-color: #f0f0f0;
}
.support-users li.selected {
  background-color: #c0c0c0;
}
.support-messages {
  padding: 1rem;
}
.support-messages input {
  width: calc(100% - 9rem);
}
.support-messages ul {
  height: calc(100vh - 18rem);
  max-height: calc(100vh - 18rem);
  overflow: scroll;
}
.support-messages li {
  margin-bottom: 1rem;
}

.support-users span {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  position: absolute;
  margin-left: -25px;
  margin-top: 10px;
}
.support-users .offline {
  background-color: #808080;
}
.support-users .online {
  background-color: #20a020;
}
.support-users .unread {
  background-color: #f02020;
}

/* OTP */

.otp-field {
  height: 32px;
  width: 32px;
  font-size: 16px;
  justify-content: center;
  margin: 2px;
  display: inline-flex;
}

.otp-container{
  justify-self: auto;
  margin: auto;
}

